import React from "react";
import { Route, Router } from "react-router-dom";
import "./App.css";
import Form from "./components/Form";

const App = () => {
  return (
    <>
      <div className="App">
        <Form />
      </div>
    </>
  );
};

export default App;
