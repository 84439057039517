import React, { useState } from "react";
import {
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBCardTitle,
  MDBCardHeader,
  MDBContainer,
  MDBCard,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
} from "mdb-react-ui-kit";
import axios from "axios";

const Form = () => {
  const [form, setForm] = useState({});
  const [studDetails, setStudDetails] = useState([]);
  const [studModal, setStudModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCheck = () => {
    if (Object.keys(form).length === 0) return false;
    setLoading(true);
    axios
      .get("https://hostel.run.edu.ng/api/get_student_profile", {
        params: { mat_no: form.mat_no, request_type: "get" },
      })
      .then((res) => {
        setLoading(false);
        setStudDetails(res.data.data);
        console.log(res.data.data);
        setStudModal(!studModal);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response) {
          alert(err.response.data.msg);
        } else {
          alert("Network Error!");
        }
      });
  };

  const handleCheckIN = () => {
    setLoading(true);
    axios
      .post("https://hostel.run.edu.ng/api/get_student_profile", {
        mat_no: form.mat_no,
        request_type: "post",
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        alert(res.data.msg);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          alert(err.response.data.msg);
        } else {
          alert("Network Error!");
        }
      });
  };

  return (
    <>
      <MDBContainer breakpoint="md">
        <MDBCard alignment="center">
          <MDBCardHeader>RUN Gatepass</MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>Enter Student's Matric Number Here</MDBCardTitle>
            <MDBInput
              id="mat_no"
              wrapperClass="mb-4"
              label="Matric Number"
              onChange={handleChange}
            />
            <MDBBtn type="submit" className="mb-4" block onClick={handleCheck}>
              {loading ? (
                <MDBSpinner role="status">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "View Student"
              )}
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>

      <MDBModal show={studModal} setShow={setStudModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Student Details</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setStudModal(false)}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div>
                <MDBIcon icon="check-circle" className="me-2 text-success" />
                Name : {studDetails.surname + " " + studDetails.firstname}
                <hr></hr>
                <MDBIcon icon="check-circle" className="me-2 text-success" />
                Programme : {studDetails.programme_id_FK}
                <hr></hr>
                <MDBIcon icon="check-circle" className="me-2 text-success" />
                Level : {studDetails.current_level}
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setStudModal(false)}>
                Close
              </MDBBtn>
              <MDBBtn color="success" onClick={handleCheckIN}>
                {loading ? (
                  <MDBSpinner role="status">
                    <span className="visually-hidden">Loading...</span>
                  </MDBSpinner>
                ) : (
                  "Check IN"
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default Form;
